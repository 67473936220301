import { Injectable } from '@angular/core';

import { WindowService } from '@yoimo/joymo-ui';

import { environment } from '@environment';
import { LoggingService } from './logging.service';
import { pushToDataLayer } from '@core/utilities';

@Injectable({ providedIn: 'root' })
export class TagManagerService {
  private isInitialized?: boolean;

  constructor(private windowService: WindowService) {}

  loadGtmScript(): void {
    if (this.isInitialized) {
      return;
    }

    this.isInitialized = true;

    pushToDataLayer<'gtm.js'>('gtm.js', {
      start: new Date().getTime(),
    });

    const gtmScript = this.windowService.document.createElement('script');

    gtmScript.id = 'GTMscript';
    gtmScript.async = true;
    gtmScript.src =
      'https://www.googletagmanager.com/gtm.js?id=' +
      environment.google?.analytics?.gtmId;

    this.windowService.document.head.append(gtmScript);

    gtmScript.addEventListener('error', () => {
      LoggingService.setBreadcrumb('Failed to load GTM');
    });
  }
}
