import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { Firestore } from '@angular/fire/firestore';
import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import {
  ChannelWithDocId,
  getChannelNavigation,
} from '@yoimo/client-sdk/channels';
import {
  BreakpointsService,
  ButtonModule,
  CardComponent,
  CardModule,
  IconModule,
  MenuComponent,
  TagCardComponent,
  WindowService,
  SkeletonCardComponent,
} from '@yoimo/joymo-ui';
import {
  Observable,
  combineLatest,
  filter,
  map,
  of,
  switchMap,
  take,
} from 'rxjs';
import { EventWithDocId } from '@yoimo/client-sdk/events';
import { getNamedTagFromSourceList } from '@yoimo/client-sdk/named-tags';
import {
  ChannelNavigation,
  EventListSource,
  LinkNavigationResource,
  NamedTag,
  NamedTagType,
} from '@yoimo/interfaces';

import { environment } from '@environment';
import {
  EventsService,
  PlatformService,
  ScopeService,
  UserOnboardingService,
} from '@core/services';
import { InfoBlockComponent, HelpComponent } from 'shared-lib';
import { ListAsMapPipe, getMailToLink } from 'shared-lib/core';

type UIRootNavigationResource =
  | NamedTagGroup
  | EventGroup
  | ListGroup
  | LinkNavigationResource;

type EventGroup = {
  type: 'EVENTS';
  label?: string;
  sources: { label: string; items$: Observable<EventWithDocId[]> }[];
  showEmptyState$: Observable<boolean>;
};

type NamedTagGroup = {
  type: 'NAMED_TAGS';
  tagType: NamedTagType;
  label?: string;
  groupBy?: string;
  items$: Observable<NamedTag[]>;
};

type ListGroup = {
  type: 'MENU';
  label: string;
  items: LinkNavigationResource[];
};

@Component({
  standalone: true,
  selector: 'joymo-drawer',
  imports: [
    CommonModule,
    RouterLink,
    IconModule,
    MenuComponent,
    ButtonModule,
    HelpComponent,
    ListAsMapPipe,
    TagCardComponent,
    CardComponent,
    CardModule,
    InfoBlockComponent,
    SkeletonCardComponent,
  ],
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements OnInit, AfterViewInit {
  @Input() canSubscribe$?: Observable<boolean>;
  @Output() onSubscribeClick = new EventEmitter<void>();
  @ViewChild(MenuComponent) private readonly menu?: MenuComponent;
  @ViewChild('drawer', { read: ElementRef }) drawer!: ElementRef;

  readonly NO_EVENTS_LABEL = $localize`:@@navigationNoEvents:No events found`;

  /** @deprecated Should react to `channel$` */
  readonly homepageUrl = this.scopeService.getHomepageUrl();
  readonly channel$ = this.scopeService.listenToChannel();
  /** @deprecated Duplicated from FooterComponent - componentize? */
  readonly supportLink$: Observable<string | null>;
  /** @deprecated Duplicated from FooterComponent - componentize? */
  readonly defaultSupportEmail = environment.joymo.defaultSupportEmail
    ? getMailToLink(environment.joymo.defaultSupportEmail)
    : null;

  /** Internal links */
  readonly links: { icon: string; label: string; route: string }[] = [];
  /** Dynamic navigation options */
  readonly navigationResources: UIRootNavigationResource[] = [];
  activeNavigation?: UIRootNavigationResource;

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    protected platformService: PlatformService,
    protected breakpointService: BreakpointsService,
    private scopeService: ScopeService,
    private eventService: EventsService,
    private fs: Firestore,
    private windowService: WindowService,
    private router: Router,
    private userOnboardingService: UserOnboardingService
  ) {
    this.supportLink$ = this.channel$.pipe(
      map(({ branding }) => {
        const { contactInformation } = branding;
        if (!contactInformation?.supportEmail) return this.defaultSupportEmail;
        return getMailToLink(contactInformation?.supportEmail);
      })
    );
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) return;

    this.channel$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((channel) => {
        const channelNav = getChannelNavigation(channel);
        const parsedChannelNav = this.getNavigationResources(
          channel,
          channelNav
        );

        if (channelNav.some((nav) => nav.type === 'CALENDAR')) {
          this.links.push({
            icon: 'calendar',
            label: $localize`:@@drawerCalendarLink:Calendar`,
            route: 'calendar',
          });
        }

        this.navigationResources.push(...parsedChannelNav);
      });
  }

  ngAfterViewInit(): void {
    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd;
        }),
        switchMap((_) => this.breakpointService.isMobile$()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((isMobile) => {
        this.userOnboardingService.addHostForFeature(
          'PAGES',
          this.drawer,
          'bottom',
          isMobile ? 'left' : 'center'
        );
      });
  }

  closeDrawer(): void {
    this.activeNavigation = undefined;
    this.menu?.close();
  }

  setActiveNavigation(option: UIRootNavigationResource): void {
    if (option.type === 'LINK') {
      this.navigateToLink(option, this.homepageUrl);
      return;
    }
    this.activeNavigation = option;
  }

  /** @deprecated Can be achieved by template using regular <a> tags or [routerLink] */
  navigateToLink(item: LinkNavigationResource, baseUrl: string | null): void {
    switch (item.linkType) {
      case 'url':
        item.external
          ? this.windowService.window?.open(item.url, '_blank')
          : (this.windowService.document.location.href = item.url);
        break;
      case 'tag':
        this.router.navigate([baseUrl, 'tags', item.tagName]);
        break;
      case 'event':
        this.router.navigate([baseUrl, 'events', item.eventId]);
        break;
      case 'video':
        this.router.navigate([baseUrl, 'videos', item.videoId]);
        break;
      case 'page':
        this.router.navigate([baseUrl, 'pages', item.pageId]);
        break;
    }
    this.closeDrawer();
  }

  private getEventNav(
    label: string | undefined,
    sources: { label: string; source: EventListSource }[]
  ): EventGroup {
    const eventGroup: EventGroup = {
      type: 'EVENTS',
      label: label || $localize`:@@navigationEventsLabel:Events`,
      sources: sources.map(({ label, source }) => ({
        label,
        items$: this.scopeService.scope$.pipe(
          switchMap(({ scope, scopeId }) =>
            this.eventService.getEvents$(source, { scope, scopeId })
          )
        ),
      })),
      showEmptyState$: of(false),
    };

    const groupItems: Observable<EventWithDocId[]>[] = eventGroup.sources.map(
      (group) => group.items$
    );

    eventGroup.showEmptyState$ = combineLatest(groupItems).pipe(
      map((res) => res.every((g) => g.length === 0))
    );

    return eventGroup;
  }

  private getNavigationResources(
    channel: ChannelWithDocId,
    navigation: ChannelNavigation
  ): UIRootNavigationResource[] {
    return navigation.reduce<UIRootNavigationResource[]>((res, item) => {
      if (item.type === 'MENU' || item.type === 'LINK') return [...res, item];
      if (item.type === 'EVENTS') {
        const eventNav = this.getEventNav(item.label, item.listSources || []);
        return !eventNav.sources.length ? res : [...res, eventNav];
      }
      if (item.type === 'NAMED_TAGS') {
        return [
          ...res,
          {
            type: 'NAMED_TAGS',
            label: item.label || $localize`:@@navigationTagsLabel:Tags`,
            tagType: item.tagType,
            groupBy: item.tagType,
            items$: getNamedTagFromSourceList(
              this.fs,
              { type: item.tagType },
              { scope: 'CHANNEL', scopeId: channel.docId }
            ),
          },
        ];
      }
      return res;
    }, []);
  }
}
