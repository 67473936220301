import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  UrlTree,
  createUrlTreeFromSnapshot,
} from '@angular/router';

import { AuthService, PlatformService } from '@core/services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private platformService: PlatformService
  ) {}
  /**
   * Consumes an auth token if a token is provided in the url.
   * This will block the navigation until the token is consumed and
   * will resume the navigation to the url without the token query param.
   * This approach is much more transparent than the "resolver" which can
   * introduce race conditions.
   */
  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    if (this.platformService.isServer()) {
      return true;
    }

    const { token, ...restQueryParams } = route.queryParams;

    if (!token) {
      // User might or might not be logged in. Let Firebase resolve that
      return true;
    }

    // User is trying to log in. Wait for the response, then set the user on the service
    const user = await this.authService.signInWithCustomToken(token);
    this.authService.setUser(user);

    return createUrlTreeFromSnapshot(route, [], restQueryParams);
  }
}
