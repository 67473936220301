import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@core/guards/auth.guard';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './features/not-found/not-found.component';
import { ScopeResolver } from './scope.resolver';
import { SlugGuard } from '@core/guards/slug.guard';
import { environment } from '@environment';
import { ChannelPageResolver } from '@core/resolvers/channel-page.resolver';
import { PageBuilderSmartComponent } from './features/page/page-builder-smart.component';

const scopedRoutes: Routes = [
  {
    path: '',
    canActivate: [SlugGuard],
    children: [
      {
        path: 'videos',
        loadChildren: () =>
          import('./features/video/video.module').then((m) => m.VideoModule),
      },
      {
        path: 'events',
        loadChildren: () =>
          import('./features/page/page-routes').then((m) => m.eventRoutes),
      },
      {
        path: 'tags',
        loadChildren: () =>
          import('./features/page/page-routes').then((m) => m.tagRoutes),
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./features/page/page-routes').then(
            (m) => m.dynamicPageRoutes
          ),
      },
      {
        path: 'calendar',
        loadChildren: () =>
          import('./features/page/page-routes').then((m) => m.calendarRoute),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./features/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: '',
        pathMatch: 'full',
        component: PageBuilderSmartComponent,
        resolve: { pageScope: ChannelPageResolver },
      },
    ],
  },
];

/**
 * List of entrypoints.
 * Since the Angular Router checks for matches from first to last,
 * the `:slug` route must always be the last one to be added.
 * Otherwise, all routes will be considered slugs.
 * This is why it's manually pushed after the rest of routes are assigned.
 */
const rootRoutes: Routes = [
  { path: '404', canActivate: [AuthGuard], component: NotFoundComponent },
  {
    path: ':slug',
    canActivate: [AuthGuard],
    resolve: { scope: ScopeResolver },
    runGuardsAndResolvers: 'paramsChange',
    children: [...scopedRoutes, { path: '**', component: NotFoundComponent }],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [
    PageBuilderSmartComponent,
    RouterModule.forRoot(rootRoutes, {
      initialNavigation: 'enabledBlocking',
      enableTracing: environment.enableRouterTracing || false, // Disable for prod
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollOffset: [0, 100],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
