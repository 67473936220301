<nav
  *ngIf="scope$ | async as scope; else unscopedHeaderTpl"
  class="joymo--header__nav"
>
  <header
    *ngIf="channel$ | async as channel"
    [ngClass]="[channel ? 'grid' : 'block']"
  >
    <joymo-drawer
      *ngIf="breakpointService.isMobile$() | async"
      [canSubscribe$]="canSubscribe$"
      (onSubscribeClick)="openTickets()"
    />

    <div class="logos flex flex-align-center">
      <a
        *ngIf="homepageUrl$ | async as homepageUrl"
        [routerLink]="homepageUrl"
        class="logos__brand"
      >
        <img [src]="channel.branding.logoImage" [alt]="channel.name" />
      </a>
      <span class="block logos__divider"></span>
      <ng-container
        *ngIf="isBetaVersion$ | async; then joymoLogosBeta; else joymoLogos"
      ></ng-container>
    </div>

    <!-- @FIXME: Can work with only 1 instance of <joymo-drawer /> -->
    <joymo-drawer *ngIf="!(breakpointService.isMobile$() | async)" />

    <div *ngIf="!isServer" class="profile flex flex-justify-end gap-16">
      <yo-button
        *ngIf="canSubscribe$ | async"
        class="none lg:block"
        role="primary"
        size="xsmall"
        id="header_btn_subscribe"
        (click)="openTickets()"
      >
        {{ subscriptionBtnLabel }}
      </yo-button>
      <ng-container
        *ngIf="user$ | async; then userMenuTpl; else authenticateTpl"
      ></ng-container>
    </div>
  </header>
</nav>

<ng-template #unscopedHeaderTpl>
  <a
    class="flex flex-justify-center flex-align-center default-header h-100"
    href="https://joymo.tv"
    target="_blank"
  >
    <yo-icon [icon]="defaultIcon" size="auto" height="20" width="72"></yo-icon>
  </a>
</ng-template>

<!-- User -->
<ng-template #userMenuTpl>
  <yo-menu *ngIf="user$ | async as user" [type]="'menu'">
    <yo-avatar
      #avatar
      yo-menu-trigger
      class="clickable"
      size="sm"
      [photoUrl]="user.photoURL"
      [name]="user.displayName"
      [email]="user.email"
      [hasNotification]="!!(requiresUserAction$ | async)"
    ></yo-avatar>
    <li yo-menu-header>
      <a i18n="@@HeaderMenuMyTicketsOption">
        <strong>{{ user.displayName || user.email }}</strong>
      </a>
      <small
        *ngIf="user.displayName"
        class="block text-sm text-secondary m-t-6"
      >
        {{ user.email }}
      </small>
    </li>

    <li yo-menu-section>
      <ng-container *ngIf="profileLinks$ | async as profileLinks; else loader">
        <a
          class="flex flex-align-center gap-16 p-12"
          *ngFor="let link of profileLinks"
          [routerLink]="link.route"
          (click)="profileMenu?.close()"
        >
          <yo-icon [icon]="link.icon"></yo-icon>
          <strong>{{ link.label }}</strong>
        </a>
      </ng-container>
      <ng-template #loader>
        <yo-icon
          icon="loader"
          size="xxl"
          class="block m-x-auto m-y-8"
        ></yo-icon>
      </ng-template>
    </li>

    <li yo-menu-item (click)="profileMenu?.close(); logOut()">
      <span class="flex flex-align-center gap-16">
        <yo-icon icon="logout"></yo-icon>
        <strong i18n="@@headerMenuLogOutOption">Log out</strong>
      </span>
    </li>
  </yo-menu>
</ng-template>

<!-- SignIn -->
<ng-template #authenticateTpl>
  <yo-button
    role="primary"
    size="xsmall"
    i18n="@@headerSignInButtonLabel"
    id="header_btn_signin"
    (click)="goToAuthenticationUrl()"
    >Sign in</yo-button
  >
</ng-template>

<ng-template #joymoLogos>
  <a href="https://joymo.tv" target="_blank">
    <yo-icon icon="powered-by-joymo" size="auto"></yo-icon>
  </a>
</ng-template>

<ng-template #joymoLogosBeta>
  <ul class="flex flex-column gap-4">
    <li>
      <a href="https://joymo.tv" target="_blank">
        <yo-icon icon="powered-by-joymo-oneliner" size="auto"></yo-icon>
      </a>
    </li>
    <li>
      <yo-icon icon="beta" size="xxl"></yo-icon>
    </li>
  </ul>
</ng-template>
