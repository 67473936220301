import * as Sentry from '@sentry/angular-ivy';

import {
  APP_BASE_HREF,
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import {
  Firestore,
  connectFirestoreEmulator,
  getFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { FooterComponent } from 'shared-lib';
import { HeaderComponent } from './layout/header/header.component';
import {
  AccessControlService,
  EventsService,
  LoggingService,
  ScopeService,
  UserOnboardingService,
  TicketOptionsService,
} from '@core/services';
import { Router } from '@angular/router';
import {
  ButtonModule,
  IconModule,
  ModalComponent,
  ToastComponent,
} from '@yoimo/joymo-ui';
import { environment } from '../environments/environment';
import { TicketOptionsModule } from './features/ticket-options/ticket-options.module';
import { UserOnboardingComponent } from './features/userOnboarding/user-onboarding.component';
import {
  PROVIDED,
  TextParser,
  PARSER_IMPLEMENTATION_TOKEN,
} from 'shared-lib/core';
import { localeMapping } from '@core/utilities';

let firestoreInstance: Firestore | undefined = undefined;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => {
      if (firestoreInstance) {
        return firestoreInstance;
      }
      firestoreInstance = getFirestore();

      if (environment.emulator) {
        connectFirestoreEmulator(firestoreInstance, 'localhost', 8080);
      }

      return firestoreInstance;
    }),
    provideFunctions(() =>
      getFunctions(undefined, environment.firebase.locationId)
    ),
    HeaderComponent,
    FooterComponent,
    ModalComponent,
    IconModule,
    ButtonModule,
    ToastComponent,
    TicketOptionsModule,
    UserOnboardingComponent,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    Location,
    { provide: ErrorHandler, useClass: LoggingService },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: PARSER_IMPLEMENTATION_TOKEN,
      useClass: TextParser,
    },
    {
      provide: PROVIDED.ticketOptionsService,
      useExisting: TicketOptionsService,
    },
    {
      provide: PROVIDED.scopeService,
      useExisting: ScopeService,
    },
    {
      provide: PROVIDED.accessControlService,
      useExisting: AccessControlService,
    },
    {
      provide: PROVIDED.eventService,
      useExisting: EventsService,
    },
    {
      provide: PROVIDED.userOnboardingService,
      useExisting: UserOnboardingService,
    },
    {
      provide: PROVIDED.localeMapping,
      useValue: localeMapping,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
