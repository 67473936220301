<yo-menu
  *ngIf="breakpointService.isMobile$() | async; else desktopTmpl"
  type="drawer"
  #drawer
>
  <yo-icon icon="menu" class="clickable" yo-menu-trigger />
  <div yo-menu-content class="sidepanel--container">
    <header class="p-y-16 p-x-12 border-b border-default">
      <yo-icon
        icon="clear"
        class="clickable m-x-auto close"
        (click)="closeDrawer()"
        [hidden]="activeNavigation"
      />
      <div class="flex" [hidden]="!activeNavigation">
        <yo-icon icon="arrow_left" (click)="activeNavigation = undefined" />
        <strong class="w-100 text-lg text-center">
          {{ activeNavigation?.label }}
        </strong>
      </div>
    </header>
    <div
      class="sidepanel--content p-12"
      [ngClass]="{ 'sidepanel--content-with-footer': !activeNavigation }"
    >
      <ng-container *ngTemplateOutlet="sidePanelDefaultTpl"></ng-container>
      <ng-container *ngIf="activeNavigation; then activeNavTpl"></ng-container>
    </div>
    <footer
      *ngIf="canSubscribe$ | async"
      [hidden]="activeNavigation"
      class="p-12 border-t border-default"
    >
      <yo-button
        class="block"
        [fullWidth]="true"
        role="primary"
        (click)="closeDrawer(); onSubscribeClick.emit()"
      >
        <span i18n="@@sidePanelSubscribeButtonLabel">Subscribe now</span>
      </yo-button>
    </footer>
  </div>
</yo-menu>

<ng-template #desktopTmpl>
  <nav class="flex flex-align-center flex-nowrap w-100" #drawer>
    <a
      *ngFor="let link of links"
      class="gap-6 nav-link text-nodecoration"
      [routerLink]="[homepageUrl, link['route']]"
    >
      <yo-icon [icon]="link.icon" />
      <strong class="m-l-6">{{ link.label }}</strong>
    </a>
    <ng-container *ngFor="let nav of navigationResources">
      <a
        *ngIf="nav.type === 'LINK'"
        class="gap-6 nav-link"
        (click)="setActiveNavigation(nav)"
      >
        <strong class="m-l-6">{{ nav.label }}</strong>
      </a>
      <yo-menu *ngIf="nav.type !== 'LINK' && nav.type !== 'MENU'" type="drawer">
        <a
          class="gap-4 nav-link"
          yo-menu-trigger
          (click)="setActiveNavigation(nav)"
        >
          <strong class="m-r-4">{{ nav.label }}</strong>
          <yo-icon icon="arrow_down" />
        </a>
        <div yo-menu-content>
          <ng-container *ngTemplateOutlet="activeNavTpl"></ng-container>
        </div>
      </yo-menu>
      <yo-menu *ngIf="nav.type === 'MENU'">
        <a
          class="gap-4 nav-link"
          yo-menu-trigger
          (click)="setActiveNavigation(nav)"
        >
          <strong class="m-r-4">{{ nav.label }}</strong>
          <yo-icon icon="arrow_down" />
        </a>
        <li
          *ngFor="let item of nav.items"
          yo-menu-item
          (click)="navigateToLink(item, homepageUrl)"
        >
          {{ item.label }}
        </li>
      </yo-menu>
    </ng-container>
  </nav>
</ng-template>

<ng-template #sidePanelDefaultTpl>
  <div [hidden]="activeNavigation">
    <ul class="flex flex-column gap-8">
      <li
        *ngFor="let link of links"
        class="flex flex-column flex-justify-center flex-align-center w-100 text-center"
      >
        <a
          *ngIf="homepageUrl"
          [routerLink]="[homepageUrl, link['route']]"
          class="m-x-auto p-x-56 p-y-24 w-100 clickable bg-transparent text-nodecoration flex-justify-center flex gap-8"
          (click)="closeDrawer()"
        >
          <yo-icon [icon]="link['icon']" size="xl" class="m-b-4" />
          <strong class="text-wrap text-lg">{{ link['label'] }}</strong>
        </a>
      </li>
      <li
        *ngFor="let nav of navigationResources"
        class="sidepanel--group flex flex-justify-between flex-align-center w-100 p-x-12 p-y-16"
        (click)="setActiveNavigation(nav)"
      >
        <strong class="text-lg">{{ nav.label }}</strong>
        <yo-icon *ngIf="nav.type !== 'LINK'" icon="arrow_right" />
      </li>
    </ul>
    <joymo-help
      *ngIf="channel$ | async as channel"
      class="p-12 m-t-12"
      [isLarge]="false"
      [supportEmailLink]="(supportLink$ | async) || defaultSupportEmail"
      [faqLink]="channel.branding.contactInformation?.faqLink"
      [streamHelpText]="channel.branding.contactInformation?.streamHelpText"
    ></joymo-help>
  </div>
</ng-template>

<ng-template #activeNavTpl>
  <ng-container [ngSwitch]="activeNavigation?.type">
    <ng-container *ngSwitchCase="'NAMED_TAGS'">
      <ng-container *ngTemplateOutlet="namedTagTmpl"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'EVENTS'">
      <ng-container *ngTemplateOutlet="eventsTmpl"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'MENU'">
      <ng-container *ngTemplateOutlet="mobileMenuTmpl"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #eventsTmpl>
  <ul
    *ngIf="activeNavigation && activeNavigation.type === 'EVENTS'"
    class="flex flex-column gap-24"
  >
    <ng-container *ngFor="let group of activeNavigation.sources">
      <ng-container
        *ngIf="(group.items$ | async) || [].constructor(4) as items"
      >
        <li *ngIf="items.length">
          <strong *ngIf="group.label" class="block m-b-12">{{
            group.label
          }}</strong>
          <section class="event-cards">
            <ng-container *ngFor="let item of items">
              <a
                *ngIf="item; else skeletonCard"
                class="text-nodecoration"
                [routerLink]="[homepageUrl, 'events', item.docId]"
                (click)="closeDrawer()"
              >
                <yo-card class="h-100">
                  <yo-video-card
                    [imageUrl]="item.posterImage"
                    class="m-b-4"
                  ></yo-video-card>
                  <p class="text-sm p-8">
                    <strong class="text-wrap">{{ item.title }}</strong>
                  </p>
                </yo-card>
              </a>
            </ng-container>
          </section>
        </li>
      </ng-container>
    </ng-container>
    <joymo-info-block
      *ngIf="activeNavigation.showEmptyState$ | async"
      heading=""
      [description]="NO_EVENTS_LABEL"
      type="INFO"
    >
    </joymo-info-block>
  </ul>
</ng-template>

<ng-template #namedTagTmpl>
  <ng-container
    *ngIf="activeNavigation && activeNavigation.type === 'NAMED_TAGS'"
  >
    <ul
      *ngIf="activeNavigation.items$ | async as items"
      class="flex flex-column gap-24"
    >
      <li
        *ngFor="
          let group of items
            | listAsMap : activeNavigation.groupBy || ''
            | keyvalue
        "
      >
        <header *ngIf="group.key" class="m-b-12">
          <strong>{{ group.key }}</strong>
        </header>
        <section class="tag-cards">
          <a
            *ngFor="let item of group.value"
            class="text-nodecoration"
            [routerLink]="[homepageUrl, 'tags', item.name]"
            (click)="closeDrawer()"
          >
            <yo-tag-card
              [size]="(breakpointService.isMobile$() | async) ? 'md' : 'sm'"
              [name]="item.displayName || item.name"
              [imageUrl]="item.logo"
            ></yo-tag-card>
          </a>
        </section>
      </li>
    </ul>
  </ng-container>
</ng-template>

<ng-template #mobileMenuTmpl>
  <ng-container *ngIf="activeNavigation && activeNavigation.type === 'MENU'">
    <ul class="flex flex-column gap-8">
      <li
        class="sidepanel--group p-x-12 p-y-16"
        *ngFor="let item of activeNavigation.items"
        (click)="navigateToLink(item, homepageUrl)"
      >
        <strong class="text-lg">{{ item.label }}</strong>
      </li>
    </ul>
  </ng-container>
</ng-template>

<ng-template #skeletonCard>
  <yo-skeleton-card [type]="'card'" class="block p-4 p-b-12"></yo-skeleton-card>
</ng-template>
