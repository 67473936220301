import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Firestore } from '@angular/fire/firestore';

import { Observable, catchError, map, of, switchMap } from 'rxjs';
import {
  BrandingOptions,
  BrandingService,
  ChannelCardComponent,
} from '@yoimo/joymo-ui';
import {
  ChannelScope,
  ChannelWithDocId,
  OrganizationScope,
  getChannelsByDomain,
} from '@yoimo/client-sdk/channels';

import { environment } from '@environment';
import { PlatformService, ScopeService } from '@core/services';

@Component({
  standalone: true,
  imports: [CommonModule, ChannelCardComponent],
  selector: 'joymo-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  host: {
    class: 'flex flex-column flex-justify-center h-100',
  },
})
export class NotFoundComponent {
  readonly channel$: Observable<ChannelWithDocId | undefined>;
  readonly channels$: Observable<ChannelWithDocId[]>;

  constructor(
    private scopeService: ScopeService,
    private fs: Firestore,
    private brandingService: BrandingService,
    private platformService: PlatformService
  ) {
    const scope$: Observable<OrganizationScope | ChannelScope | undefined> =
      this.scopeService.scope$.pipe(catchError((err, caught) => of(undefined)));

    this.channel$ = scope$.pipe(
      map((scope) => {
        if (!scope) {
          this.applyBranding(environment.behavior.defaultTheme);
          return undefined;
        }
        const channel = this.scopeService.getChannelFromScope(scope);
        this.applyBranding(channel.branding);
        return channel;
      })
    );

    this.channels$ = scope$.pipe(
      switchMap((scope) => {
        if (!scope) {
          return this.getAllChannels$();
        }
        return of([]);
      })
    );
  }

  getChannelUrl(channel: ChannelWithDocId): string {
    return new URL(
      channel.slug,
      'https://' + (channel.domain || environment.behavior.defaultDomain)
    ).href;
  }

  private getAllChannels$(): Observable<ChannelWithDocId[]> {
    let domain: string | null = this.platformService.getDomain();

    if (domain in environment.behavior.domainAliases) {
      domain = environment.behavior.domainAliases[domain];
    }

    // Look for all channels by current domain
    return getChannelsByDomain(this.fs, domain).pipe(
      switchMap((channels) => {
        // Display all channels with current domain
        return channels.length
          ? of(channels)
          : getChannelsByDomain(this.fs, null);
      })
    );
  }

  private applyBranding(branding: BrandingOptions): void {
    this.brandingService.setTheme(branding, true);
  }
}
