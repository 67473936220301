<yo-popover>
  <section class="p-16 position-relative">
    <yo-icon
      icon="clear"
      size="md"
      class="position-absolute position-top position-right m-8 text-secondary clickable"
      (click)="skipFlow()"
    >
    </yo-icon>
    <div *ngIf="currentStep$ | async as ctx">
      <p class="text-md text-semibold">{{ ctx.title }}</p>
      <p class="m-t-4 text-sm">{{ ctx.description }}</p>
      <ng-container [ngTemplateOutlet]="steps.length > 1 ? progressBar : null">
      </ng-container>
      <div class="flex gap-8 m-t-16">
        <yo-button
          class="w-100"
          [fullWidth]="true"
          *ngFor="let action of ctx.actions; index as index"
          [role]="index === ctx.actions.length - 1 ? 'primary' : 'tertiary'"
          (click)="action.onClick()"
        >
          {{ action.label }}
        </yo-button>
      </div>
    </div>
  </section>
</yo-popover>

<ng-template #progressBar>
  <span class="block m-t-12 text-sm p-b-4">
    {{ currentIndex + 1 + '/' + steps.length }}
  </span>
  <div class="flex w-100">
    <span
      class="block progress-bar-completed"
      [ngStyle]="{
        width: 'calc(' + currentIndex + 1 / steps.length + ' * 100%)',
        'border-bottom': '1px solid'
      }"
    ></span>
    <span
      class="block border-default border-b border-solid"
      [ngStyle]="{
        width: 'calc(100% - ' + currentIndex + 1 / steps.length + ' * 100%)'
      }"
    ></span>
  </div>
</ng-template>
