import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@angular/fire/auth';
import {
  AuthService,
  PlatformService,
  UserOnboardingService,
} from '@core/services';
import { PopoverAction, PopoverWithAction } from '@core/utilities';
import {
  ButtonModule,
  IconModule,
  PopoverComponent,
  PopoverService,
  WindowService,
} from '@yoimo/joymo-ui';
import {
  ReplaySubject,
  Subject,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';

@Component({
  standalone: true,
  selector: 'joymo-user-onboarding',
  templateUrl: './user-onboarding.component.html',
  styleUrls: ['./user-onboarding.component.scss'],
  imports: [CommonModule, PopoverComponent, IconModule, ButtonModule],
})
export class UserOnboardingComponent implements OnInit, OnDestroy {
  readonly previousLabel = $localize`:@@previous:Previous`;
  readonly nextLabel = $localize`:@@next:Next`;
  readonly doneLabel = $localize`:@@done:Done`;
  readonly gotItLabel = $localize`:@@gotIt:Got it`;

  private destroy$ = new Subject<void>();
  protected steps: PopoverWithAction[] = [];
  currentStep$ = new ReplaySubject<PopoverWithAction | undefined>(1);
  protected currentIndex = -1;
  private isOpen = false;
  private idsCompleted: string[] = [];

  constructor(
    private popoverService: PopoverService,
    protected userOnboardingService: UserOnboardingService,
    private platformService: PlatformService,
    private windowService: WindowService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      return;
    }

    this.userOnboardingService.reset$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.exitCurrentFlow();
      });

    this.authService.user$
      .pipe(
        filter((user): user is User => !!user),
        switchMap((user) => this.userOnboardingService.getSteps$(user.uid))
      )
      .pipe(
        takeUntil(this.destroy$),
        map((steps) => {
          return steps.map((step, index) => ({
            ...step,
            actions: this.getStepAction(index, steps.length),
          }));
        })
      )
      .subscribe((steps) => {
        this.handleStepsUpdate(steps);
      });
  }

  handleStepsUpdate(steps: PopoverWithAction[]) {
    if (!steps.length) {
      return;
    }
    if (this.isOpen) {
      this.steps;
      this.steps[this.steps.length - 1].actions = this.getStepAction(
        this.steps.length - 1,
        this.steps.length + steps.length
      );
      steps.forEach((step, index) =>
        this.steps.push({
          ...step,
          actions: this.getStepAction(
            this.steps.length + index,
            this.steps.length + steps.length
          ),
        })
      );
      this.steps.forEach(
        (step, index) =>
          (step.actions = this.getStepAction(index, this.steps.length))
      );
      return;
    }
    this.idsCompleted = [];
    this.currentIndex = -1;
    this.steps = steps;
    // start flow
    this.step(1);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getStepAction(index: number, noOfSteps: number): PopoverAction[] {
    if (noOfSteps === 1) {
      return [
        {
          label: this.gotItLabel,
          onClick: () => this.step(1),
        },
      ];
    }
    if (noOfSteps > 1) {
      if (index === 0) {
        return [
          {
            label: this.nextLabel,
            onClick: () => this.step(1),
          },
        ];
      }
      if (index === noOfSteps - 1) {
        return [
          {
            label: this.previousLabel,
            onClick: () => this.step(-1),
          },
          {
            label: this.doneLabel,
            onClick: () => this.step(1),
          },
        ];
      }

      return [
        {
          label: this.previousLabel,
          onClick: () => this.step(-1),
        },
        {
          label: this.nextLabel,
          onClick: () => this.step(1),
        },
      ];
    }
    throw new Error('Should not be in this state');
  }

  step(offset: 1 | -1) {
    if (offset === -1 && this.currentIndex < 0) {
      return;
    }
    if (this.currentIndex > -1) {
      const currentFeature = this.steps[this.currentIndex].feature;
      if (!this.idsCompleted.includes(currentFeature)) {
        this.idsCompleted.push(currentFeature);
      }
    }
    if (offset === 1 && this.currentIndex === this.steps.length - 1) {
      this.close();
      return;
    }
    this.currentIndex += offset;
    this.popoverService.open(this.steps[this.currentIndex]);
    this.currentStep$.next(this.steps[this.currentIndex]);
    this.isOpen = true;
    this.scrollIfNotVisible();
  }

  scrollIfNotVisible() {
    const offset = 100;
    if (!this.windowService.window) {
      return;
    }
    const { bottom, top } =
      this.steps[this.currentIndex].host.nativeElement.getBoundingClientRect();
    if (bottom > this.windowService.window.innerHeight || top < 0) {
      this.windowService.window.scrollTo({
        top: top + this.windowService.window.scrollY - offset,
        behavior: 'smooth',
      });
    }
  }

  close(): void {
    this.userOnboardingService
      .saveUserStatus$(this.idsCompleted)
      .pipe(take(1))
      .subscribe();
    this.isOpen = false;
    this.popoverService.close();
    this.currentStep$.next(undefined);
  }

  skipFlow() {
    this.idsCompleted = this.steps.map((step) => step.feature);
    this.close();
  }

  exitCurrentFlow() {
    this.isOpen = false;
    this.popoverService.close();
    this.currentStep$.next(undefined);
  }
}
