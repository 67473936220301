import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Resolve,
  Route,
  Router,
} from '@angular/router';

import { BrandingService, CardImageDirective } from '@yoimo/joymo-ui';
import { ChannelWithDocId, getActivePage } from '@yoimo/client-sdk/channels';
import { catchError, map, Observable, of } from 'rxjs';
import { getPageHeader } from '@yoimo/client-sdk/pages';

import {
  AccessControlService,
  LoggingService,
  MetaService,
  PromotionService,
  ScopeService,
} from '@core/services';

import { Injectable } from '@angular/core';
import { PageScope } from 'shared-lib/core';
import { mapPageToUIPage } from '@core/utilities/pages';

@Injectable({ providedIn: 'root' })
export class ChannelPageResolver implements Resolve<PageScope | undefined> {
  constructor(
    private router: Router,
    private metaService: MetaService,
    private scopeService: ScopeService,
    private brandingService: BrandingService,
    private accessControlService: AccessControlService,
    private promotionService: PromotionService
  ) {}

  resolve(
    activeRoute: ActivatedRouteSnapshot
  ): Observable<PageScope | undefined> {
    return this.scopeService.listenToChannel().pipe(
      map((channel: ChannelWithDocId) => {
        const page = getActivePage(channel);

        if (!page) throw new Error('ChannelWithoutActivePage:' + channel.docId);
        page.brandingOverride &&
          this.brandingService.setOverride(page.brandingOverride, true);

        this.metaService.setTags({
          title: channel.name,
          description: channel.description,
          image: channel.branding.logoImage,
        });

        if (page.metaTags) {
          this.metaService.setRawTags(page.metaTags);
        }

        CardImageDirective.setFallbackImage(channel.branding.logoImage);

        const header = getPageHeader(page, { channel });
        const activePage = mapPageToUIPage(
          page,
          header,
          'DEFAULT',
          activeRoute.queryParams['tabId']
        );
        const defaultPurchaseOptions$ = this.accessControlService
          .getApplicablePlans$(channel.docId)
          .pipe(map((plans) => plans.map((plan) => ({ plan }))));

        return {
          channel,
          activePage,
          userHasAccess$:
            this.accessControlService.isUserSubscribedToChannel$(channel),
          promotionalBlock$: this.promotionService.getPromotionalBlock$(
            header,
            channel,
            defaultPurchaseOptions$
          ),
        };
      }),
      catchError((err: Error) => {
        LoggingService.logError(err);
        this.router.navigate(['/404'], { skipLocationChange: true });
        return of(undefined);
      })
    );
  }
}
