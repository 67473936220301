import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { LoggingService, MetaService, ScopeService } from '@core/services';
import { Observable, map, catchError, of, tap } from 'rxjs';
import { Scope, getScope } from '@yoimo/client-sdk/channels';

import { Firestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { PlatformService } from '@core/services/platform.service';
import { environment as env } from '@environment';
import { BrandingService } from '@yoimo/joymo-ui';

@Injectable({ providedIn: 'root' })
export class ScopeResolver implements Resolve<Scope> {
  constructor(
    private fs: Firestore,
    private router: Router,
    private brandingService: BrandingService,
    private platformService: PlatformService,
    private scopeService: ScopeService,
    private metaService: MetaService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Scope> {
    const domain = this.resolveDomain();
    const slug = route.paramMap.get('slug');

    if (!slug) throw new Error('Slug must be defined');

    return getScope(this.fs, domain, slug).pipe(
      map((scope) => {
        if (!scope) {
          this.scopeService.setScope(undefined);
          throw new Error(`No scope found: ${slug}@${domain}`);
        }

        this.scopeService.setScope(scope);
        this.brandingService.setTheme(
          { ...this.scopeService.getChannel().branding },
          true
        );
        return scope;
      }),
      catchError((err) => {
        LoggingService.logError(err);
        this.router.navigate(['/404'], {
          skipLocationChange: true,
          queryParamsHandling: 'preserve',
        });
        return of(err);
      }),
      tap((scope) => {
        if (env.behavior.redirectToCanonicalUrl) {
          this.platformService.ensureValidDomainForScope(scope);
        }
        this.metaService.setFavicon(scope.scopeId);
      })
    );
  }

  private resolveDomain(): string | null {
    if (env.behavior.emulateDomain) {
      return env.behavior.emulateDomain;
    }

    const currentDomain = this.platformService.getDomain();

    if (currentDomain in env.behavior.domainAliases) {
      return env.behavior.domainAliases[currentDomain];
    }

    return currentDomain;
  }
}
