import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  createUrlTreeFromSnapshot,
  UrlTree,
} from '@angular/router';

@Injectable({ providedIn: 'root' })
export class SlugGuard implements CanActivate {
  /**
   * A scoped route must be provided with a scope, else redirect to the default "home" slug
   */
  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    if (!route.paramMap.get('slug')) {
      return createUrlTreeFromSnapshot(route, ['/home'], route.queryParams);
    }

    return true;
  }
}
