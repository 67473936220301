import {
  AccessControlService,
  AuthService,
  PlatformService,
  ScopeService,
  UserOnboardingService,
  TicketOptionsService,
} from '@core/services';
import {
  AvatarComponent,
  BreakpointsService,
  ButtonModule,
  IconModule,
  MenuComponent,
  OutsideClickListenerDirective,
  WindowService,
} from '@yoimo/joymo-ui';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ChannelWithDocId, Scope } from '@yoimo/client-sdk/channels';
import {
  Observable,
  Subject,
  combineLatest,
  map,
  of,
  switchMap,
  takeUntil,
  take,
  firstValueFrom,
  filter,
  delay,
} from 'rxjs';
import { ProfileRoute, ProfileService } from '@core/services/profile.service';
import { NavigationEnd, Router, RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { User } from '@angular/fire/auth';
import { environment } from '@environment';
import { DrawerComponent } from './drawer/drawer.component';

@Component({
  selector: 'joymo-header',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    RouterModule,
    IconModule,
    RouterModule,
    OutsideClickListenerDirective,
    MenuComponent,
    AvatarComponent,
    DrawerComponent,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: { class: 'block' },
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MenuComponent) readonly profileMenu?: MenuComponent;
  @ViewChild('avatar', { read: ElementRef }) avatar!: ElementRef;

  readonly defaultIcon = environment.behavior.defaultIcon;

  readonly scope$: Observable<Scope | undefined>;
  readonly homepageUrl$: Observable<string>;
  readonly profileLinks$: Observable<ProfileRoute[]>;
  readonly isBetaVersion$: Observable<boolean>;

  canSubscribe$?: Observable<boolean>;

  readonly isServer: boolean;
  readonly channel$: Observable<ChannelWithDocId>;
  readonly user$: Observable<User | null>;
  readonly requiresUserAction$ = this.accessControlService.requiresUserAction$;
  readonly subscriptionBtnLabel = $localize`:@@HeaderSubscribeButtonLabel:Subscribe now`;

  private destroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private scopeService: ScopeService,
    private windowService: WindowService,
    private accessControlService: AccessControlService,
    private platformService: PlatformService,
    private ticketOptionsService: TicketOptionsService,
    private profileService: ProfileService,
    protected breakpointService: BreakpointsService,
    private userOnboardingService: UserOnboardingService
  ) {
    this.user$ = this.authService.user$;
    this.scope$ = this.scopeService.scope$;
    this.channel$ = this.scopeService.listenToChannel();
    this.isServer = this.platformService.isServer();
    this.profileLinks$ = this.profileService.getProfileRoutes$();
    this.homepageUrl$ = this.scopeService.scope$.pipe(
      map((scope) => this.scopeService.getHomepageUrlFromScope(scope))
    );
    this.isBetaVersion$ = this.channel$.pipe(
      map(({ domain }) => environment.behavior.betaDomains.includes(domain!))
    );
  }

  ngOnInit() {
    if (this.isServer) {
      return;
    }

    this.canSubscribe$ = this.checkSubscription();
  }

  ngAfterViewInit(): void {
    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd;
        }),
        delay(500), // Added delay to trigger after navigation bar
        takeUntil(this.destroy$)
      )
      .subscribe((_) => {
        this.userOnboardingService.addHostForFeature(
          'PROFILE',
          this.avatar,
          'bottom',
          'right'
        );
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkSubscription(): Observable<boolean> {
    return this.channel$.pipe(
      switchMap((channel) =>
        combineLatest([
          of(channel),
          this.accessControlService.isUserSubscribedToChannel$(channel),
        ])
      ),
      switchMap(([channel, subscribed]) => {
        // If the user has subscribed then no need to check on the applicable products
        return subscribed ? of(false) : this.hasAvailablePlans$(channel);
      }),
      takeUntil(this.destroy$)
    );
  }

  async logOut(): Promise<void> {
    await this.authService.auth.signOut();
    const homepageUrl = await firstValueFrom(this.homepageUrl$);
    this.router.navigate([homepageUrl]);
  }

  openTickets() {
    this.ticketOptionsService.openAllPurchaseOptions();
  }

  goToAuthenticationUrl(): void {
    this.channel$.pipe(take(1)).subscribe((channel) => {
      const authUrl = this.authService.getAuthenticationUrl(
        channel.docId,
        channel.slug,
        this.windowService.document.location.href
      );
      this.windowService.document.location.assign(authUrl);
    });
  }

  private hasAvailablePlans$(channel: ChannelWithDocId): Observable<boolean> {
    return this.accessControlService.getApplicablePlans$(channel.docId).pipe(
      map((plans) => {
        return plans.length > 0;
      })
    );
  }
}
