<section
  class="standard-margins m-y-64 flex flex-column flex-align-center gap-64"
>
  <header class="text-center">
    <img src="assets/404.gif" alt="Content not found" />
    <h1 class="heading-md lg:heading-lg m-t-24" i18n="@@notFoundMessage">
      The page you're looking for
      <br />
      doesn't exist.
    </h1>
    <p class="m-t-12 text-md text-secondary">
      <ng-container *ngIf="channel$ | async">
        <span i18n="@@notFoundScoped">
          Please check the address you typed. If everything
          <br />
          looks fine then probably the page was deleted.
        </span>
        <br />
      </ng-container>
      <span i18n="@@notFoundErrorCode">Error code 404.</span>
    </p>
  </header>

  <div *ngIf="channels$ | async as channels" class="text-center">
    <p
      *ngIf="channels.length"
      class="text-lg text-semibold m-b-24"
      i18n="@@notFoundExploreChannels"
    >
      Here are some channels you can explore
    </p>
    <ul
      *ngIf="channels$ | async as channels"
      class="flex flex-justify-center flex-wrap gap-8"
    >
      <li *ngFor="let channel of channels">
        <yo-channel-card
          [name]="channel.name"
          [branding]="channel.branding"
          [imageUrl]="channel.branding.logoImage"
          [url]="getChannelUrl(channel)"
        ></yo-channel-card>
      </li>
    </ul>
  </div>

  <div *ngIf="channel$ | async" class="text-center">
    <p class="text-lg text-semibold m-b-24" i18n="@@notFoundEventsList">
      But these ones should work
    </p>
  </div>
</section>
