import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { BrandingService, ModalComponent } from '@yoimo/joymo-ui';
import { TagManagerService } from '@core/services/tagmanager.service';
import {
  AuthService,
  PlatformService,
  ScopeService,
  TicketOptionsService,
} from '@core/services';
import { ConsentService } from '@core/services/consent.service';
import { combineLatest, first, of, switchMap } from 'rxjs';
import { TicketOptionsComponent } from './features/ticket-options/ticket-options.component';
import { environment } from '@environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    class: 'flex flex-column h-100',
  },
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ModalComponent) private disconnectModal!: ModalComponent;
  disconnectReason?: {
    icon: string;
    title: string;
    description: string;
    actionLabel: string;
  };
  @ViewChild('ticketOptionsModal')
  private ticketOptionsModal!: TicketOptionsComponent;
  readonly joymoSupportEmail = environment.joymo.defaultSupportEmail;

  constructor(
    private authService: AuthService,
    private consentService: ConsentService,
    private scopeService: ScopeService,
    private tagManagerService: TagManagerService,
    private platformService: PlatformService,
    private ticketOptionsService: TicketOptionsService
  ) {}

  ngOnInit(): void {
    BrandingService.applyCssConstants();

    if (this.platformService.isServer()) return;

    this.tagManagerService.loadGtmScript();
    this.initForcedDisconnectNotifier();
    this.disconnectIfMissingConsentEntry();
    this.ticketOptionsService.checkForPreSelectedTicket();
  }

  ngOnDestroy(): void {
    this.ticketOptionsService.onDestroy();
  }

  ngAfterViewInit(): void {
    this.ticketOptionsService.setModalReference(this.ticketOptionsModal);
  }
  initForcedDisconnectNotifier() {
    this.authService.forcedDisconnect$.subscribe((reason) => {
      switch (reason) {
        case 'LOGGED_ELSWHERE':
          this.disconnectReason = {
            icon: 'devices',
            title: $localize`:@@forcedDisconnectLoggedElsewhereTitle:Why have I been disconnected?`,
            description: $localize`:@@forcedDisconnectLoggedElsewhereDescription:You have been logged out because this account is in use already. The account is limited to one device at a time. To regain access, please sign back in.`,
            actionLabel: $localize`:@@forcedDisconnectLoggedElsewhereActionLabel:OK`,
          };
          this.disconnectModal.open();
          break;
      }
    });
  }

  private disconnectIfMissingConsentEntry(): void {
    combineLatest([this.scopeService.listenToChannel(), this.authService.user$])
      .pipe(
        switchMap(([channel, user]) => {
          if (!user || !channel) return of(false);
          return this.consentService.isMissingConsentEntry$(
            channel.docId,
            user.uid
          );
        }),
        first((isMissingEntry): isMissingEntry is true => !!isMissingEntry)
      )
      .subscribe(() => this.authService.auth.signOut());
  }

  setPaymentResult(res: 'success' | 'failure') {
    this.ticketOptionsService.onPaymentResult$.next(res);
  }
}
