<joymo-header></joymo-header>

<section class="main overflow-visible">
  <router-outlet></router-outlet>
</section>

<joymo-footer [joymoSupportEmail]="joymoSupportEmail"></joymo-footer>

<yo-modal #disconnectModal size="sm" [dismissable]="false">
  <section
    yo-modal-content
    *ngIf="disconnectReason"
    class="flex flex-column gap-16 flex-align-center"
  >
    <yo-icon
      [icon]="disconnectReason.icon"
      size="auto"
      height="80"
      width="160"
      class="block"
    ></yo-icon>
    <header class="heading-sm m-t-8 text-center">
      {{ disconnectReason.title }}
    </header>
    <p class="text-center">
      {{ disconnectReason.description }}
    </p>
    <!-- TODO: update with troubleshoot link when available -->
    <!-- <a
      href="https://help.joymo.tv/knowledge/do-i-need-an-app-to-watch"
      target="_blank"
      i18n="@@troubleshootingGuideLinkLabel"
      >Our troubleshooting guide</a
    > -->
    <yo-button
      class="w-100 m-t-16"
      role="primary"
      [fullWidth]="true"
      (click)="disconnectModal.close()"
      >{{ disconnectReason.actionLabel }}</yo-button
    >
  </section>
</yo-modal>

<!-- App level ticket options instance -->
<joymo-ticket-options
  #ticketOptionsModal
  (onPaymentResult)="setPaymentResult($event)"
></joymo-ticket-options>

<joymo-user-onboarding></joymo-user-onboarding>

<yo-toast></yo-toast>
